<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form @submit.prevent="onSubmit">
        <b-card no-body class="mb-2">
          <b-card-header class="align-items-center">
            <unicon name="users-alt" width="18" height="18" class="mr-1" />
            <h4 class="mr-auto mb-0">معلومات الموظف</h4>
          </b-card-header>
          <b-card-body>
            <b-card-text>
              <b-row>
                <b-col cols="12" lg="9" order="1" order-lg="0">
                  <b-row>
                    <b-col cols="12" md="6">
                      <BTextInputWithValidation
                        rules="required"
                        name="الاسم"
                        label="الاسم"
                        v-model="employeeDto.firstName"
                        placeholder="دخل الاسم "
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <BTextInputWithValidation
                        rules="required"
                        name="الكنية"
                        label="الكنية"
                        v-model="employeeDto.lastName"
                        placeholder="ادخل الكنية "
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <BTextInputWithValidation
                        rules="digits:10|integer"
                        name="رقم الجوال"
                        label="رقم الجوال"
                        v-model="employeeDto.mobileNumber"
                        placeholder="دخل رقم الجوال "
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <BTextInputWithValidation
                        rules="email"
                        name="البريد الإلكتروني"
                        label="البريد الإلكتروني"
                        v-model="employeeDto.email"
                        placeholder="ادخل البريد الإلكتروني"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <BDatepickerWithValidation
                        rules="required"
                        label="تاريخ الميلاد "
                        name="تاريخ الميلاد"
                        v-model="employeeDto.dob"
                        :max="new Date()"
                        placeholder="أدخل تاريخ الميلاد"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <BTextInputWithValidation
                        rules="required"
                        name="المنصب الوظيفي"
                        label="المنصب الوظيفي"
                        v-model="employeeDto.position"
                        placeholder="ادخل المنصب "
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" lg="3" order="0" order-lg="1">
                  <label>صورة الموظف</label>
                  <fileDragDrop
                    @uploaded="uploadEmployeeImage"
                    id="employee-photo"
                    height="100px"
                    type="image"
                    title="صورة جديدة"
                  />
                  <img
                    :src="
                      !employeeDto.imageDto.base64
                      ?  !employeeDto.imageDto.path
                        ? '/images/website/employee.svg'
                        : employeeDto.imageDto.path.replace(
                            '~',
                            $store.getters['app/domainHost']
                        )
                      : employeeDto.imageDto.base64
                    "
                    style="max-height: 320px; object-fit: fill"
                    class="w-100 mb-1"
                  />
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col>
                <div class="d-flex">
                  <b-button
                    class="mr-1"
                    variant="primary"
                    style="max-width: 100px"
                    type="submit"
                    >تعديل</b-button
                  >
                  <b-button
                    to="../"
                    type="button"
                    variant="outline-primary"
                    style="max-width: 100px"
                    >تراجع</b-button
                  >
                </div>
              </b-col>
              <b-col style="display: flex; justify-content: flex-end">
                <b-button
                  style="max-width: 100px"
                  variant="outline-primary"
                  type="button"
                  @click="deleteEmployee(id)"
                  >حذف</b-button
                >
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import BDatepickerWithValidation from "@core/components/inputs/BDatepickerWithValidation";
import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
import { ValidationObserver } from "vee-validate";
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    lang: String,
    id: String,
  },
  components: {
    BDatepickerWithValidation,
    BTextInputWithValidation,
    ValidationObserver,
    fileDragDrop,
  },
  computed: {
    ...mapGetters(["employeeDto"]),
  },
  created() {
    this.getEmployeeById(this.id);
  },
  methods: {
    ...mapActions(["getEmployeeById", "deleteEmployee", "updateEmployee"]),
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          this.updateEmployee({
            id: this.employeeDto.id,
            firstName: this.employeeDto.firstName,
            lastName: this.employeeDto.lastName,
            email: this.employeeDto.email,
            position: this.employeeDto.position,
            mobileNumber: this.employeeDto.mobileNumber,
            dob: this.employeeDto.dob,
            imageDto: {
              id: this.employeeDto.imageDto.id,
              path: this.employeeDto.imageDto.path,
              base64: this.employeeDto.imageDto.base64,
            },
          });
        }
      });
    },
    uploadEmployeeImage(file) {
      console.log(file)
        this.employeeDto.imageDto.base64 = file[0].base64;
    },
  },
};
</script>
